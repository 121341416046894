import signData from '../upstreams/baseSignData';
import SignsModule from './SignsModule';
import Layout from './Layout';
import Attribution from './Attribution';
import Ad from './Ad';
import Seasonal from './Seasonal';
import { getNav } from '../upstreams/nav';
import { getAds } from '../upstreams/modulous';
import { setAssetPath } from '../utils/helpers';
import TrackingProvider from '../hooks/useTracking';
import Newsletter from './Newsletter';
import { settings } from '@buzzfeed/rig';

function Edition({ ads, seasonal, navdata, host, timestamp, isWebview, edition }) {
  const newsletterEnabled = edition === 'us';
  const mainClass = `main ${edition} ${newsletterEnabled ? '' : 'no-newsletter'}`;
  return (
    <TrackingProvider>
      <Layout host={host} timestamp={timestamp} ads={ads} navdata={navdata} isWebview={isWebview}>
        <main className={mainClass}>
          <h1 className='main__title'>Daily Horoscopes</h1>
          <p className='main__blurb'>Get your free daily horoscope. Discover what&#39;s in store for your astrology sign for the day, your week in romance and more.</p>
          <Seasonal seasonal={seasonal} isWebview={isWebview} />
          <SignsModule page='hub' isWebview={isWebview} />
          <Attribution />
          <Ad
            className='advertisement bottom_leaderboard'
            isInject={true}
            code={ads.leaderboard_flex}
          />
          {newsletterEnabled && <Newsletter host={host} pageId='hub'/>}
        </main>
      </Layout>
    </TrackingProvider>
  );
}

export async function gsPaths(editions) {
  let paths = [];
  if (process.env['CLUSTER']) {
    paths = editions.map((edition) => ({
      params: {
        edition,
      },
    }))
  }
  return { paths, fallback: 'blocking' }
}

export async function gsProps(params) {
  const { edition, isWebview = false } = params;
  const hostMap = settings.get('hosts');
  const host = hostMap[edition];
  const [ navdata, ads ] = await Promise.all([getNav({ edition }), getAds({site: edition})]);
  const seasonal = {};
  for (const [sign, data] of Object.entries(signData)) {
    if (edition === 'uk' && data.isTodayUK() || edition === 'us' && data.isTodayUS()) {
      seasonal.name = sign;
      seasonal.constellationSrc = setAssetPath(`/images/constellations/${sign}.svg`);
      seasonal.symbolSrc = setAssetPath(`/images/signs/sign-${sign}.svg`);
    }
  }
  return {
    props: {
      seasonal,
      navdata,
      ads,
      host,
      isWebview,
      timestamp: new Date().toString(),
      edition,
      pageInfo: {
        source: 'web_huffpost',
        context_page_type: 'feed',
        context_page_id: 'huffpost_horoscopes_hub',
        page_edition: `en-${edition}`,
        destination: 'huffpost',
      },
    },
  };
}

export default Edition;
